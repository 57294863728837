<template>
  <section>
    <div class="container hidden section-prizes">
      <div class="prizes-container">
        <div class="section-header">
          <span class="subheading">Prizes</span>
          <h2 class="heading-secondary">Win thousands in cash prizes</h2>
          <p class="heading-description">
            There are multiple ways to win! See below for a breakdown of the
            different prizes.
          </p>
        </div>
        <div class="grid grid--12-cols">
          <div class="prize-box hidden main-prize prize-1">
            <h3 class="prize-value"><span class="dollar-sign">$</span>1,000</h3>
            <h3 class="heading-tertiary">Best thesis</h3>
            <p class="prize-description">
              Robin Speziale and the INVRS team will select the best thesis
              based on the contest criteria.
            </p>
          </div>
          <div class="prize-box hidden main-prize prize-2">
            <h3 class="prize-value"><span class="dollar-sign">$</span>1,000</h3>
            <h3 class="heading-tertiary">Most engagement</h3>
            <p class="prize-description">
              The INVRS community can vote for their favourite pitch by liking
              and commenting.
            </p>
          </div>
          <div class="prize-box hidden ongoing-prize prize-3">
            <h3 class="prize-value"><span class="dollar-sign">$</span>100/m</h3>
            <h3 class="heading-tertiary">Best returns</h3>
            <p class="prize-description">
              The recommendation with the highest performance at each month-end
              gets paid.
            </p>
          </div>

          <div class="prize-box hidden bonus-prize prize-4">
            <h3 class="prize-value"><span class="dollar-sign">$</span>2,000</h3>
            <h3 class="heading-tertiary">2 birds, 1 stone</h3>
            <p class="prize-description">
              Win best thesis or most engagement and highest 1-year performance
              to win.
            </p>
          </div>
          <div class="prize-box hidden bonus-prize prize-5">
            <h3 class="prize-value"><span class="dollar-sign">$</span>5,000</h3>
            <h3 class="heading-tertiary">Trifecta</h3>
            <p class="prize-description">
              Win best thesis, most engagement, and highest 1-year performance
              to win.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      prizes: [],
      obsOptionsPrizes: {
        root: null,
        threshold: 0.5,
      },
      obsOptionsSection: {
        root: null,
        threshold: 0.2,
      },
      section: "",
    };
  },
  mounted() {
    this.prizes = document.querySelectorAll(".prize-box");
    this.section = document.querySelector(".section-prizes");
    const prizesObserver = new IntersectionObserver(
      this.revealPrizes,
      this.obsOptionsPrizes
    );
    const sectionObserver = new IntersectionObserver(
      this.revealSection,
      this.obsOptionsSection
    );
    prizesObserver.observe(this.section);
    sectionObserver.observe(this.section);
  },
  methods: {
    revealPrizes(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.prizes.forEach((v, i) => {
            setTimeout(() => v.classList.remove("hidden"), i * 100);
          });
        }
      });
    },
    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },
  },
};
</script>

<style scoped>
section {
  background: linear-gradient(180deg, #f2f4fa 32.54%, #f2f4fa00 100%);
}

.section-prizes {
  padding: 9.6rem 3.2rem;
  transition: all 0.3s ease-in;
}

.heading-description {
  line-height: 3.5rem;
  width: 60%;
  margin-bottom: 4.8rem;
}

.prize-1 {
  grid-column: 1 / 5;
}
.prize-2 {
  grid-column: 5 / 9;
}
.prize-3 {
  grid-column: 9 / 13;
}
.prize-4 {
  grid-column: 3 / 7;
}
.prize-5 {
  grid-column: 7 / 11;
}

.main-prize,
.ongoing-prize,
.bonus-prize {
  position: relative;
  overflow: hidden;
}

.main-prize::after,
.ongoing-prize::after,
.bonus-prize::after {
  position: absolute;
  top: 8%;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  padding: 0.8rem 8rem;
  transform: rotate(45deg);
}

.main-prize::after {
  content: "Main";
  right: -18%;
  background-color: #32364e;
}

.ongoing-prize::after {
  content: "Ongoing";
  right: -22%;
  background-color: #693bf5;
}

.bonus-prize::after {
  content: "Bonus";
  right: -20%;
  background-color: #33cc99;
}

.prize-box {
  background-color: #f9fafd;
  padding: 2.4rem;
  border-radius: 8px;
  box-shadow: 0 0 2.4rem hsla(231, 22%, 25%, 0.2);

  transition: all 0.4s ease;
}

.prize-box:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 1.2rem 4.8rem hsla(231, 22%, 25%, 0.2);
}

.dollar-sign {
  font-size: 1.8rem;
  margin-right: 0.2rem;
}

.prize-value {
  color: #693bf5;
  font-size: 3rem;
  margin-bottom: 2.4rem;
}

.prize-description {
  font-size: 1.8rem;
  line-height: 1.6;
}

.hidden {
  transform: translateY(4.8rem);
  opacity: 0;
}

/* **************************** */
/* BELOW 976px (landscape tablets) */
/* **************************** */
@media (max-width: 61em) {
  .prize-1 {
    grid-column: 1 / 7;
  }
  .prize-2 {
    grid-column: 7 / 13;
  }
  .prize-3 {
    grid-column: 1 / 7;
  }
  .prize-4 {
    grid-column: 7 / 13;
  }
  .prize-5 {
    grid-column: 4 / 10;
  }
  .main-prize::after {
    right: -12%;
  }

  .ongoing-prize::after {
    right: -15%;
  }

  .bonus-prize::after {
    right: -13%;
  }
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .main-prize::after {
    right: -15%;
  }

  .ongoing-prize::after {
    right: -17%;
  }

  .bonus-prize::after {
    right: -16%;
  }

  .heading-description {
    width: 100%;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .grid--12-cols {
    grid-template-columns: 1fr;
  }

  .prize-1,
  .prize-2,
  .prize-3,
  .prize-4,
  .prize-5 {
    grid-column: 1;
  }

  .main-prize::after,
  .ongoing-prize::after,
  .bonus-prize::after {
    transform: rotate(35deg);
  }

  .main-prize::after {
    right: -10%;
  }

  .ongoing-prize::after {
    right: -11%;
  }

  .bonus-prize::after {
    right: -10%;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .section-prizes {
    padding: 9.6rem 2.4rem;
  }

  .main-prize::after {
    right: -12%;
  }

  .ongoing-prize::after {
    right: -15%;
  }

  .bonus-prize::after {
    right: -13%;
  }

  .prize-box {
    padding: 1.8rem;
  }
}
</style>
