<template>
  <router-view></router-view>
  <section v-if="contestPageVisible">
    <contest-hero></contest-hero>
    <contest-judges></contest-judges>
    <contest-prizes></contest-prizes>
    <contest-steps></contest-steps>
  </section>
</template>

<script>
import ContestHero from "../components/contest/ContestHero.vue";
import ContestJudges from "../components/contest/ContestJudges.vue";
import ContestPrizes from "../components/contest/ContestPrizes.vue";
import ContestSteps from "../components/contest/ContestSteps.vue";

export default {
  components: {
    ContestHero,
    ContestJudges,
    ContestPrizes,
    ContestSteps,
  },
  computed: {
    contestPageVisible() {
      return !this.$route.fullPath.includes("growth2022");
    },
  },
};
</script>
