<template>
  <section>
    <div class="container section-judges hidden">
      <div class="section-header">
        <span class="subheading">Judges</span>
        <h2 class="heading-secondary">Judged by:</h2>
      </div>
      <div class="judges-flex">
        <div class="judge-container hidden">
          <img src="../../assets/robin.jpg" class="judge-img" />
          <div class="judge-content">
            <h3 class="heading-tertiary">Robin Speziale</h3>
            <p class="judge-text">
              Robin is the national best-selling author of "Market Masters" and
              "Capital Compounders".
            </p>
          </div>
        </div>
        <div class="judge-container hidden">
          <img src="../../assets/jen.jpg" class="judge-img" />
          <div class="judge-content">
            <h3 class="heading-tertiary">Jennifer Cameron</h3>
            <p class="judge-text">
              Jen is the co-founder of INVRS and is a CPA and
              CFA charterholder.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      section: "",
      judges: [],
      obsOptionsSection: {
        root: null,
        threshold: 0.2,
      },
      obsOptionsJudges: {
        root: null,
        threshold: 0.4,
      },
    };
  },
  mounted() {
    this.section = document.querySelector(".section-judges");
    this.judges = document.querySelectorAll(".judge-container");
    const sectionObserver = new IntersectionObserver(
      this.revealSection,
      this.obsOptionsSection
    );
    const judgesObserver = new IntersectionObserver(
      this.revealJudges,
      this.obsOptionsJudges
    );
    sectionObserver.observe(this.section);
    judgesObserver.observe(this.section);
  },
  methods: {
    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },
    revealJudges(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.judges.forEach((v, i) => {
            setTimeout(() => v.classList.remove("hidden"), i * 100);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.section-judges {
  padding: 9.6rem 0;
  transition: all 0.3s ease-in;
}

.hidden {
  transform: translateY(4.8rem);
  opacity: 0;
}

.judges-flex {
  display: flex;
}

.judge-container {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in;
  margin-right: 1.2rem;
}

.judge-container:last-child {
  margin-right: 0;
}

.judge-img {
  height: 10rem;
  margin-right: 1.8rem;
  border-radius: 8px;
}

.judge-content {
  display: flex;
  flex-direction: column;
}

.judge-text {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
  .section-judges {
    padding: 9.6rem 3.2rem;
  }
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .judges-flex {
    flex-direction: column;
  }

  .judge-container {
    margin-right: 0;
    margin-bottom: 3.6rem;
  }

  .judge-container:last-child {
    margin-bottom: 0;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .section-judges {
    padding: 9.6rem 2.4rem;
  }
}
</style>
